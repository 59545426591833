#my-modal-input-modal {
	max-width: 285px;
  height: 230px;
	display: flex;
	flex-direction: column;
  justify-content: center;
	align-items: center;
	gap: 20px;
  padding: 0;

  .close {
    position: absolute;
    top: -8vh;
    right: -30px;
    background-color: transparent;
    border: none;

    svg {
      width: 18px;
      height: 18px;
    }
  }

	.icon {
		display: flex;
		justify-content: center;

		svg {
			width: 60px;
			height: 60px;
		}
	}

	.my-input {
		width: fit-content;
		padding: 0px 10px;

		input,
		span {
			height: 40px;
			line-height: 40px;
			font-size: 2rem;
		}
		
		input {
			text-align: center;
      max-width: 160px;
			height: 40px;
		}
		
		span {
			font-family: "BalooDa2Bold";
		}
	}

	.save {
		display: flex;
		gap: 5px;
		border: none;
		background-color: var(--primary-blue);
		border-radius: 30px;
		padding: 8px 18px;

		span {
			color: white;
			font-size: 1rem;
			letter-spacing: 0.2px;
			font-family: "BalooDa2SemiBold";
		}

		svg {
			margin: auto 0;
		}
	}
}
