.funding-duration-selector {
	display: flex;
  flex-wrap: wrap;
	gap: 10px;
	justify-content: center;

	button {
		display: flex;
		flex-direction: column;
		padding: 7px 18px;
		border-radius: 10px;
		border: none;
		background-color: var(--tertiary-background);
		align-items: center;
    height: fit-content;

		span {
			font-family: "BalooDa2SemiBold";
			line-height: 18px;
			font-size: 1rem;

			&:last-child {
				font-size: 0.813rem;
			}
		}

		&.selected {
			background-color: var(--primary-violet);

			span {
				color: white;
			}
		}
	}

	@media screen and (min-width: 840px) {
		justify-content: left;
		
    button {
      padding: 7px 18px;
    }
  }
}
