#my-modal-duration-modal {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 35px;
	padding: 20px 0px;

	.close {
		position: absolute;
		top: -8vh;
		right: 0;
		background-color: transparent;
		border: none;

		&:focus {
			outline: none;
		}

		svg {
			width: 18px;
			height: 18px;
		}
	}

	.icon {
		display: flex;
		justify-content: center;

		svg {
			width: 60px;
			height: 60px;
		}
	}

	.save {
		display: flex;
		gap: 5px;
		border: none;
		background-color: var(--primary-blue);
		border-radius: 30px;
		padding: 8px 18px;

		span {
			color: white;
			font-size: 1rem;
			letter-spacing: 0.2px;
			font-family: "BalooDa2SemiBold";
		}

		svg {
			margin: auto 0;
		}
	}

	.Funding-duration-selector {
		justify-content: center;
	}

	@media screen and (max-width: 540px) {
		max-width: 90%;
	}
}
