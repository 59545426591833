.funding-go-to-simulation {
	display: flex;
	justify-content: center;
	border-radius: 30px;
	padding: 9px;
	border: none;
	font-size: 1rem;
	letter-spacing: 0.2px;
	gap: 8px;
	background-color: var(--primary-blue);
	height: fit-content;
	margin: auto 0;

	span {
		margin: auto 0;
		font-family: "BalooDa2SemiBold";
		color: white;
	}

	svg {
		margin: auto 0;
		width: 12px;
		height: 12px;
		position: relative;
		top: -1px;
	}

  @media screen and (min-width: 840px) {
    margin: 0;
    padding: 9px 20px;
	}
}
