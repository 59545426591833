.funding-result {
	background-color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 10px;
	border-radius: 15px;
	justify-content: space-between;

	h3 {
		font-size: 1.125rem;
	}

	p {
		font-size: 0.625rem;
		font-family: "BalooDa2Bold";

		span {
			font-size: 0.875rem;
			line-height: 34px;
			margin-right: 3px;
			letter-spacing: 0.2px;

			&.value {
				font-size: 2rem;
			}
		}
	}

	.rate {
		margin-top: -4px;

		span {
			font-size: 0.875rem;
			font-family: "BalooDa2Bold";
			color: var(--secondary-background);
		}
	}

	@media screen and (min-width: 840px) {
		width: fit-content;
		padding: 0px 20px;
		justify-content: space-evenly;
	}

	@media screen and (min-width: 840px) and (max-width: 1100px) {
		justify-content: center;
		gap: 15px;
	}
}
