#funding-page {
	position: relative;
	height: 100%;
	display: grid;
	background: var(--primary-background);
	border-radius: 15px;
	padding: 10px;
	grid-template-areas:
		"result"
		"categories"
		"my-simulation"
		"note";
	gap: 20px;

	.my-title {
		grid-area: title;
		padding: 8px 20px;
	}

	.fridaa-logo {
		position: absolute;
		right: 10px;
	}

	.funding-categories {
		grid-area: categories;
	}

	.funding-result {
		grid-area: result;
	}

	.funding-go-to-simulation {
		grid-area: my-simulation;
	}

	.note {
		grid-area: note;
	}

	@media screen and (min-width: 840px) {
		padding: 30px 30px 10px;
		column-gap: 2%;
		row-gap: 20px;
		grid-template-areas:
			"result categories"
			"note note";
		grid-template-columns: auto 1fr;
	}

	.note {
		p {
			font-size: 0.75rem;
			margin: 0;
		}
	}
}
