.my-input {
	display: flex;
	align-items: center;

	&.border {
		border-bottom: 1px dashed var(--secondary-background);
	}

	input {
		background-color: transparent;
		border: none;
		color: var(--primary-blue);
		-webkit-text-fill-color: var(--primary-blue);
		font-family: "BalooDa2Bold";
		font-size: 1.25rem;
		text-align: right;
		max-width: 90px;
		box-sizing: content-box;
		opacity: 1;

		&::placeholder {
			color: var(--primary-blue);
		}

		&:focus {
			outline: none;
		}
	}

	.unit {
		box-sizing: content-box;
	}
}
