@import "./ui/styles/palette.scss";

@font-face {
	font-family: "BalooDa2Bold";
	src: local("BalooDa2-Bold"),
		url(./ui/fonts/Baloo_Da_2/BalooDa2-Bold.ttf) format("truetype");
}
@font-face {
	font-family: "BalooDa2Medium";
	src: local("BalooDa2-Medium"),
		url(./ui/fonts/Baloo_Da_2/BalooDa2-Medium.ttf) format("truetype");
}
@font-face {
	font-family: "BalooDa2";
	src: local("BalooDa2-Regular"),
		url(./ui/fonts/Baloo_Da_2/BalooDa2-Regular.ttf) format("truetype");
}
@font-face {
	font-family: "BalooDa2SemiBold";
	src: local("BalooDa2-SemiBold"),
		url(./ui/fonts/Baloo_Da_2/BalooDa2-SemiBold.ttf) format("truetype");
}

body {
	margin: 0;
	font-family: "BalooDa2", "BalooDa2Medium", "BalooDa2Bold", "BalooDa2SemiBold",
		-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
		"Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	--primary-blue: #120136;
	--primary-violet: #8770d5;
	--primary-green: #6fcd8b;
	--primary-background: #f5f7fc;
	--secondary-violet: #9083aa;
	--secondary-background: #97afce;
	--tertiary-background: #e9f2ff;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

h1,
h2,
h3,
h4,
h4,
h6,
span {
	color: var(--primary-blue);
}

h1,
h2,
h3,
h4,
h4,
h6 {
	font-family: "BalooDa2Bold";
}

h1,
h2,
h3,
h4,
h4,
h6,
p {
	margin: 0;
}

button {
	cursor: pointer;
}

p {
	color: var(--secondary-background);
}