#dialog-close {
	position: absolute;
	top: 20px;
	right: 5px;
	background-color: transparent;
	color: var(--text-medium);
	border: none;

	&:hover {
		color: var(--text-dark);
	}
}